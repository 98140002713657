import Slider from "react-slick";
import { motion } from "framer-motion";
import { fadeIn } from "../../../helpers/variants";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

export default function BannerTodos() {
  const Disclaimer =
    "La información contenida en este material no pretende reemplazar el consejo médico profesional, consulte siempre a su médico si tiene preguntas y/o preocupaciones acerca de su condición médica. Material educativo exclusiva mente para pacientes/público general. Este material está sujeto a derechos de propiedad intelectual. Este material podría contener conceptos u opiniones que son responsabilidad de los autores y no comprometen las opiniones del laboratorio auspiciante. Boehringer Ingelheim S.A., Teléfono: (+601) 319 91 00, e-mail: medfora.co@boehringer-ingelheim.com Dirección: Carrera 11 No. 84A-09 Piso 5, Bogotá D.C. Colombia.";

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const Banner1 =
    "https://www.sospecharnofalla.com/para-pacientes/fc/otros/blogs/riesgos-de-la-automedicacion";

  const Banner2 =
    "https://www.sospecharnofalla.com/para-pacientes/fc/prevencion/posters/etapas-enfermedad-rc-1-2";

  const NewCon =
    "https://www.sospecharnofalla.com/para-pacientes/generalidades-y-curiosidades/videos/nuevo-concepto-snf";
  const PrevCon =
    "https://www.sospecharnofalla.com/para-pacientes/prevencion/infografias/como-prevenir-la-falla-cardiaca";


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };
  return (
    <div>
      <Slider {...settings}>
        {/* Slider 1 */}
        {windowWidth > 767 ? (
          /* Slide 1 - PC */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-1.png"
              alt="Slide 2"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-32 text-4xl font-helvetica-bold text-white md:w-[45%] text-shadow"
              >
                Entérate de qué se considera
                <br /> automedicarse y cuáles
                <br /> son sus riesgos.
              </motion.h2>{" "}
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-72 mt-[40px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner1}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí {" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        ) : (
          /* Slide 1 - Mobile */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-1-Mobile.png"
              alt="Banner 1 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[5.7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-12 text-4xl font-bold text-white w-[95%] text-shadow"
              >
                Descubre los mitos y realidades sobre la enfermedad renal
                crónica.
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-[280px] mt-[80px] ml-[10px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner1}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí{" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        )}
        {/* Slider 2*/}
        {windowWidth > 767 ? (
          /* Slide 2 - PC */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-2.png"
              alt="Slide 2"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-32 text-4xl font-helvetica-bold text-white md:w-[63%] text-shadow"
              >
                <div>
                  Accede a toda la información
                  <br /> sobre las primeras etapas de la
                  <br />
                  enfermedad renal crónica.
                </div>
                <div className="font-helvetica-normal text-lg mt-3">
                  Informarse es el primer paso para la prevención.
                </div>
              </motion.h2>{" "}
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-72 mt-[60px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner2}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí{" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        ) : (
          /* Mobile Slide 2 */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-2-Mobile.png"
              alt="Banner 2 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[5.7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-5 text-2xl font-bold text-white w-[85%] text-shadow"
              >
                <div>
                  Accede a toda la información
                  <br /> sobre las primeras etapas de la
                  <br />
                  enfermedad renal crónica.
                </div>
                <div className="font-helvetica-normal text-base mt-3">
                  Informarse es el primer paso para la prevención.
                </div>
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-[280px] mt-[120px] ml-[10px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner2}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí{" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        )}

        {/* Slider 3*/}
        {windowWidth > 767 ? (
          /* Slide 3 - PC */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-3.png"
              alt="Slide 2"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-32 text-4xl font-helvetica-bold text-white md:w-[35%] text-shadow"
              >
                Agente:
                <div className="font-helvetica-normal text-2xl mt-2">
                  tienes la misión de explorar nuestras <b>redes sociales,</b>{" "}
                  donde te mantendremos informado
                  <br /> sobre cómo preservar tu <b>salud cardíaca.</b>
                </div>
              </motion.h2>
            </div>
          </div>
        ) : (
          /* Slide 3 - Mobile */
          <div className="relative">
            <img
              src="/images/banners-todos/Banner-3-Mobile.png"
              alt="Banner 3 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[5.7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-2 text-4xl font-bold text-white md:w-[35%] text-shadow"
              >
                Agente:
                <div className="font-helvetica-normal text-sm mt-2">
                  tienes la misión de explorar nuestras <b>redes sociales,</b>{" "}
                  donde te mantendremos informado
                  <br /> sobre cómo preservar tu <b>salud cardíaca.</b>
                </div>
              </motion.h2>
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
}
