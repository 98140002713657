import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { fadeIn } from "../../../helpers/variants";

const MainTextDoctors = ({ firstText, firstPic, secondPic, width }) => {
  return (
    <section className="sections">
      <div className="container">
        <div className="row">
          <motion.div
            variants={fadeIn("right", 0.1)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="col-sm-6 text-description"
          >
            <h2 className="title">Sobre Sospechar</h2>
            <p>{firstText}</p>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.1)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="col-sm-6"
          >
            <img src={firstPic} alt="Cardiología" />
          </motion.div>
        </div>
        <div className="row mt-[30px]">
          {width > 575 ? (
            <>
              <motion.div
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="col-sm-6"
              >
                <img src={secondPic} alt="Cardiología" />
              </motion.div>
              <motion.div
                variants={fadeIn("left", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="col-sm-6 text-description"
              >
                <h2 className="title">¿Qué es?</h2>
                <p>
                  Sospechar No Falla es una iniciativa que busca educar y
                  generar conciencia sobre enfermedades crónicas no
                  transmisibles, como la falla cardíaca, la enfermedad renal
                  crónica y la diabetes. Entre los contenidos que tenemos
                  preparados, se encuentran: datos epidemiológicos, impacto de
                  la enfermedad, información diagnóstica, casos clínicos y
                  entrevistas con expertos. Esta información se presenta con
                  base en las guías más recientes del manejo de cada enfermedad,
                  entre otras fuentes confiables. 
                </p>
              </motion.div>
            </>
          ) : (
            <>
              <div className="col-sm-6 text-description">
                <h2 className="title">¿Qué es?</h2>
                <p>
                  Sospechar No Falla es una iniciativa que busca educar y
                  generar conciencia sobre enfermedades crónicas no
                  transmisibles, como la falla cardíaca, la enfermedad renal
                  crónica y la diabetes. Entre los contenidos que tenemos
                  preparados, se encuentran: datos epidemiológicos, impacto de
                  la enfermedad, información diagnóstica, casos clínicos y
                  entrevistas con expertos. Esta información se presenta con
                  base en las guías más recientes del manejo de cada enfermedad,
                  entre otras fuentes confiables.
                </p>
              </div>
              <motion.div
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
                className="col-sm-6"
              >
                <img src={secondPic} alt="Cardiología" />
              </motion.div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

MainTextDoctors.propTypes = {
  firstText: PropTypes.string.isRequired,
  firstPic: PropTypes.string.isRequired,
  secondPic: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default MainTextDoctors;
