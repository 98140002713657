import { motion } from "framer-motion";
import { fadeIn } from "../../../helpers/variants";

export default function MediaCardPrueba() {
  return (
    <>
      <div className="grid sm:grid-cols-2 sm:gap-[50px] gap-4 ml-[40px] sm:ml-[17px]">
        <motion.div
          variants={fadeIn("up", 0.1)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.3 }}
        >
          <a
            href="https://www.instagram.com/sospechar_nofalla/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className="card media-card">
              <div className="card-image">
                <img src="/images/red-insta.png" alt="Instagram Thumbnail" />
              </div>
              <div className="card-description">
                <div>
                  <h3>Instagram</h3>
                  <p>No olvides seguirnos en nuestra red social Instagram</p>
                </div>
              </div>
            </div>
          </a>
        </motion.div>

        <motion.div
          variants={fadeIn("up", 0.1)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.3 }}
        >
          <a
            href="https://www.facebook.com/sospecharnofalla"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className="card media-card">
              <div className="card-image">
                <img src="/images/red-facebook.png" alt="Facebook Thumbnail" />
              </div>
              <div className="card-description">
                <div>
                  <h3>Facebook</h3>
                  <p>No olvides seguirnos en nuestra red social Facebook</p>
                </div>
              </div>
            </div>
          </a>
        </motion.div>
      </div>
    </>
  );
}
