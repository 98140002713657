import Slider from "react-slick";
import { motion } from "framer-motion";
import { fadeIn } from "../../../helpers/variants";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

export default function BannerMedicos() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Disclaimer =
    "Material de entrenamiento para profesionales de la salud. No es publicidad de producto. Material sujeto a derechos de propiedad intelectual. Este material podría contener conceptos u opiniones que son responsabilidad de los autores y no comprometen las opiniones del laboratorio auspiciante.  Boehringer Ingelheim S.A., Teléfono: (+601) 319 91 00, e-mail: medfora.co@boehringer-ingelheim.com. Dirección: Carrera 11 No. 84A-09 Piso 5, Bogotá D.C. Colombia.";

  const Banner1 =
    "https://www.sospecharnofalla.com/para-medicos/sobre-la-patologia/blogs/trastornos-psicologicos";
  const Banner2 =
    "https://www.sospecharnofalla.com/para-medicos/sobre-la-patologia/blogs/actualizacion-clasificacion-falla-cardiaca";

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div>
      <Slider {...settings}>
        {/* Slide 1*/}
        {windowWidth > 767 ? (
          /* Banner 1 - PC */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-1.png"
              alt="Banner 1"
              className="banner w-full object-cover"
            />
            <div className="absolute flex bottom-0 banner-title text-white text-[9px] ml-[2%] text-center bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-[140px] text-6xl font-helvetica-bold text-white text-shadow"
              >
                <div className="w-[100%] text-4xl">
                  Trastornos psicológicos
                  <br /> y falla cardíaca,
                </div>
                <div className="font-helvetica-bold text-lg w-[90%]">
                  ¿hay alguna correlación?
                  <br /> Descubre qué ha demostrado la evidencia.
                </div>
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-72 mt-[40px] mb-10 rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner1}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí {" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        ) : (
          /* Banner 1 - Mobile */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-1-Mobile.png"
              alt="Banner 1 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[5.7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-16 text-4xl font-bold text-white md:w-[35%] text-shadow"
              >
                Trastornos psicológicos
                <br /> y falla cardíaca,
                <div className="font-helvetica-bold text-lg w-[100%]">
                  ¿hay alguna correlación?
                  <br /> Descubre qué ha demostrado la evidencia.
                </div>
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-80 mt-[60px] ml-[8px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner1}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí{" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        )}
        {/* Slide 2*/}
        {windowWidth > 767 ? (
          /* Slide 2 - PC */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-2.png"
              alt="Banner 2"
              className="banner w-full object-cover"
            />
            <div className="absolute flex bottom-0 banner-title text-white text-[9px] ml-[2%] text-center bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-[140px] text-6xl font-helvetica-bold text-white text-shadow"
              >
                <div className="w-[35%] text-4xl">Agente:</div>
                <div className="text-lg font-helvetica-light mt-10 w-[100%]">
                  nuestras redes sociales son un banco de
                  <br /> información para tus pacientes. Tu misión
                  <br /> es compartirlas con ellos.
                </div>
              </motion.h2>
            </div>
          </div>
        ) : (
          /* Mobile Slide 2 */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-2-Mobile.png"
              alt="Banner 2 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[5.7px] text-center mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-16 text-4xl font-bold text-white md:w-[35%] text-shadow"
              >
                <div className="w-[35%] text-4xl">Agente:</div>
                <div className="text-lg font-helvetica-light mt-10 w-[100%]">
                  nuestras redes sociales son un banco de
                  <br /> información para tus pacientes. Tu misión
                  <br /> es compartirlas con ellos.
                </div>
              </motion.h2>
            </div>
          </div>
        )}
        {/* Slide 3*/}
        {windowWidth > 767 ? (
          /* Banner 3 - PC */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-3.png"
              alt="Banner 3"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[9px] text-center sm:ml-3.5 sm:mx-auto bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-[140px] text-6xl font-helvetica-bold text-white text-shadow"
              >
                <div className="w-[100%] text-4xl">
                  Entérate de las actualizaciones
                  <br /> sobre la clasificación
                  <br /> de la falla cardíaca
                </div>
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-72 mt-[40px] mb-10 rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner2}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí {" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        ) : (
          /* Banner 3 - Mobile */
          <div className="relative">
            <img
              src="/images/banners-medicos/Banner-3-Mobile.png"
              alt="Banner 3 Mobile"
              className="banner w-full object-cover"
            />
            <div className="absolute bottom-0 banner-title text-white text-[6px] text-center bg-[(0, 0, 0, 0.5)]">
              {Disclaimer}
            </div>
            <div className="container">
              <motion.h2
                variants={fadeIn("right", 0.1)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="banner-title absolute top-7 text-3xl font-helvetica-bold text-white w-[80%] ml-[20px] text-shadow"
              >
                Entérate de las actualizaciones
                <br /> sobre la clasificación
                <br /> de la falla cardíaca
              </motion.h2>
              <motion.button
                variants={fadeIn("", 0.5)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="absolute top-80 mt-[60px] ml-[8px] rounded-3xl px-4 py-1.5 text-[#048abf] bg-[#f3ece4]"
              >
                <Link
                  to={Banner2}
                  target="_blank"
                  className="font-helvetica-normal"
                >
                  Haz clic aquí{" >"}
                </Link>
              </motion.button>
            </div>
          </div>
        )}
      </Slider>
    </div>
  );
}
