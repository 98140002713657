import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { baseApiUrl } from "../../../../helpers/global";
import { mediaDataStartLoading } from "../../../../actions/medias";
import { motion } from "framer-motion";
import { fadeIn } from "../../../../helpers/variants";
import MediaCard from "../../../layouts/cards/MediaCard";
import Loader from "../../../layouts/loader/Loader";
import InfografiasViewer from "../../../layouts/infografias/InfografiasViewer";
import VideoPlayer from "../../../layouts/videos/VideoPlayer";
import PodcastPlayer from "../../../layouts/podcasts/PodcastPlayer";
import BlogViewer from "../../../layouts/blogs/BlogViewer";
import BreadCrumbs from "../../../layouts/breadCrumbs/BreadCrumbs";
import "./mediaScreen.styles.scss";
import { Helmet } from "react-helmet";
import Menu from "../../../layouts/header/menu/Menu";

const MediaScreen = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];

  const { section, type, media, subsection } = useParams();

  const { mediaDataLoading } = useSelector((state) => state.ui);
  const { mediaData, relatedMedias } = useSelector((state) => state.medias);

  const [showAll, setShowAll] = useState(false);

  //  ? Obtener datos de la multimedia
  const getMediaData = useCallback(() => {
    if (currentSection === "para-medicos")
      dispatch(
        mediaDataStartLoading({
          media,
          section,
          type,
          professional: true,
        })
      );
    if (currentSection === "para-pacientes")
      dispatch(
        mediaDataStartLoading({
          media,
          section,
          type,
          subsection,
          professional: false,
        })
      );
  }, [dispatch, currentSection, media, section, type, subsection]);

  // ? Mostrar listado de medias relacionadas (todas o solo las 3 primeras)
  const renderMedias = () => {
    if (relatedMedias.length === 0)
      return (
        <div className="col-md-6">
          <h5>No hay materiales relacionados</h5>
        </div>
      );

    const mediasToShow = showAll ? relatedMedias : relatedMedias?.slice(0, 3);
    const subsection_slug = subsection;

    return mediasToShow?.map((media, key) => (
      <MediaCard
        key={key}
        name={media.name}
        description={media.description}
        thumb={`${baseApiUrl}/${media.thumbnail}`}
        url={
          media.url
            ? media.url
            : `${media.section_slug}/${subsection_slug}/${media.type_slug}/${media.media_slug}`
        }
        external={!!media.url}
      />
    ));
  };

  // ? Obtener nombre del tipo de multimedia
  const getMediaTypeName = () => {
    const mediaTypes = {
      infografias: "infografías",
      videos: "videos",
      podcasts: "pódcasts",
      tests: "test",
      blogs: "blogs",
      posters: "pósters",
    };

    return mediaTypes[type] || null;
  };

  const mediaTypeData = getMediaTypeName();

  // ? Renderizar reproductor segun tipo de media
  const renderPlayer = () => {
    const mediaTypes = {
      infografias: <InfografiasViewer />,
      videos: <VideoPlayer />,
      podcasts: <PodcastPlayer />,
      blogs: <BlogViewer />,
      posters: <InfografiasViewer />,
    };

    return mediaTypes[type] || null;
  };

  useEffect(() => {
    getMediaData();
    window.scroll({ top: 0 });
  }, [getMediaData]);

  return (
    <>
      <Helmet>
        <title>{mediaData?.name}</title>
        <meta name="description" content={mediaData?.description} />
        <meta name="keywords" content={mediaData?.keywords} />
      </Helmet>
      <div className="infografia">
        <Menu />
        <div className="container">
          <h3 className="title">
            {mediaData ? (
              <>
                {mediaData?.section + " >"}{" "}
                {subsection === "generalidades"
                  ? "Generalidades y curiosidades >"
                  : ""}
                {subsection === "prevencion" ? "Prevención >" : ""}
                {subsection === "diagnostico" ? "Diagnóstico >" : ""}
                {subsection === "vcle" ? "Vivir con la enfermedad >" : ""}
                {subsection === "otros" ? "Otros  >" : ""}
                {subsection === "enfermedad"
                  ? "Vivir con la enfermedad >"
                  : ""}{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {mediaTypeData}
                </span>
              </>
            ) : (
              !mediaDataLoading && "Ups! 404"
            )}
          </h3>
          <div className="divider"></div>

          {mediaDataLoading && <Loader />}

          <h1 className="info-title">{mediaData?.name}</h1>

          {type !== "blogs" && (
            <p className="description-info">
              {mediaData
                ? mediaData.description
                : !mediaDataLoading &&
                  "Lo sentimos pero la sección o material al cual intentas acceder, no existe"}
            </p>
          )}

          {!mediaDataLoading && mediaData && (
            <>
              <motion.div
                variants={fadeIn("", 0.1)}
                initial={mediaData.type !== "Blogs" ? "hidden" : "show"}
                whileInView={"show"}
                viewport={{ once: true, amount: 0.3 }}
                className="video"
              >
                {renderPlayer()}
              </motion.div>

              {mediaData?.references && mediaData.references.length > 0 && (
                <div>
                  <h3 className="references-title">Referencias</h3>
                  <ol>
                    {mediaData.references
                      .split("||")
                      .map((reference, index) => (
                        <li key={index}>{reference}</li>
                      ))}
                  </ol>
                </div>
              )}
            </>
          )}

          <section className="new-content">
            <h2>Contenido relacionado</h2>
            <div className="row">{renderMedias()}</div>
          </section>
          <div className="flex justify-center mt-[20px] mb-[80px]">
            {!showAll && relatedMedias.length > 3 && (
              <button
                className="bg-[#048abf] w-[151px] h-[36px] flex justify-center items-center pt-[4px] rounded-[20px] text-white font-helvetica-bold text-[16px] leading-[19px]"
                onClick={() => setShowAll(true)}
              >
                Cargar mas +
              </button>
            )}
          </div>

          {mediaData && (
            <BreadCrumbs
              homeUrl={
                pathname.includes("para-medicos")
                  ? "/para-medicos/sobre-sospechar-no-falla"
                  : "/para-pacientes/inicio"
              }
              section={mediaData?.section}
              subsection={mediaData?.subsection}
              type={mediaTypeData}
              typeUrl={`/${pathname.split("/")[1]}/${mediaData?.section_slug}/${
                mediaData?.subsection_slug
              }/${mediaData?.type_slug}`}
              media={
                mediaData?.name?.length > 35
                  ? mediaData?.name.substring(0, 35) + "..."
                  : mediaData?.name
              }
            />
          )}
        </div>
        {mediaData && (
          <BreadCrumbs
            homeUrl={
              pathname.includes("para-medicos")
                ? "/para-medicos/sobre-sospechar-no-falla"
                : "/para-pacientes/inicio"
            }
            section={mediaData?.section}
            type={mediaTypeData}
            typeUrl={`/${pathname.split("/")[1]}/${mediaData?.section_slug}/${
              mediaData?.type_slug
            }`}
            media={
              mediaData?.name?.length > 35
                ? mediaData?.name.substring(0, 35) + "..."
                : mediaData?.name
            }
          />
        )}

      </div>
    </>
  );
};

export default MediaScreen;
