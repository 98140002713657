import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn } from "../../helpers/variants";

import "./Home.styles.scss";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          Falla cardíaca, enfermedad renal crónica y diabetes mellitus tipo II |
          Sospechar no falla
        </title>
        <meta
          name="description"
          content="En este sitio web encontrarás información relevante sobre cómo prevenir, sospechar y convivir con enfermedades como la falla cardíaca, la enfermedad renal crónica y la diabetes."
        />
      </Helmet>
      <div className="home-container">
        <div className="bg-blue">
          <div className="container">
            <header className="header">
              <img
                src="/images/logo.png"
                alt="Sospechar no falla logo"
                className="logo"
              />
              <div className="col-md-6 d-flex align-items-center justify-content-end text-right m-0 p-0 logos-res">
                <p className="m-0 mr-2 siguenos text-white">Síguenos:</p>
                <a
                  href="https://www.facebook.com/sospecharnofalla"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/fece-b.png"
                    className="img-fluid mr-2"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/sospechar_nofalla/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/insta-b.png"
                    className="img-fluid mr-2"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.instagram.com/sospechar_nofalla/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/tik-b.png"
                    className="img-fluid mr-2"
                    alt="TikTok"
                  />
                </a>
              </div>
            </header>
            {/** cartas */}
            <div className="content">
              <div className="row">
                <div className="col-lg-5">
                  <div className="card-content">
                    <div
                      className="card card-res-tam"
                      style={{
                        width: 330,
                        height: "auto",
                        backgroundColor: "#03598c",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="bienvenido mt-2 sospechar-res">
                          Bienvenido a <br />
                          Sospechar No Falla
                        </h5>
                        <p className="card-text texto1 mt-5 mb-3 text-sitio-res">
                          En este sitio web encontrarás información relevante
                          sobre cómo prevenir, sospechar y convivir con
                          enfermedades como la falla cardíaca, la enfermedad
                          renal crónica y la diabetes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-7">
                  <div className="class-cartas">
                    <motion.img
                      variants={fadeIn("right", 0.1)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.3 }}
                      src="/images/snf-btn-todos.jpg"
                      className="img-contenido img-res-1"
                      alt="Contenido para todos"
                      onClick={() => navigate("/para-pacientes/inicio")}
                    />
                    <motion.img
                      variants={fadeIn("left", 0.1)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.3 }}
                      src="/images/snf-btn-medicos.jpg"
                      className="img-contenido img-res-2 ml-3"
                      alt="Contenido para profesionales de la salud"
                      onClick={() => navigate("/para-medicos/iniciar-sesion")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
