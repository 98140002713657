import { useLocation } from "react-router-dom";
import { baseApiUrl } from "../../../helpers/global";

import "./Footer.styles.scss";

const Footer = () => {
  const { pathname } = useLocation();

  const changeColor =
    pathname !== "/" &&
    !pathname.includes("iniciar-sesion") &&
    !pathname.includes("registrarse") &&
    !pathname.includes("cambiar-contrasena");

  return (
    <>
      <div className={changeColor ? "footer light-blue" : "footer"}>
        <div className="container">
          <div
            className="col-md-6 align-items-center justify-content-start text-right pt-[20px] logos-res"
            style={{
              display: pathname !== "/" ? "flex" : "none",
            }}
          >
            <p className="m-0 mr-2 siguenos text-white">Síguenos:</p>
            <a
              href="https://www.facebook.com/sospecharnofalla"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/fece-b.png"
                className="img-fluid mr-2"
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/sospechar_nofalla/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/insta-b.png"
                className="img-fluid mr-2"
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.instagram.com/sospechar_nofalla/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/tik-b.png"
                className="img-fluid mr-2"
                alt="Tik tok"
              />
            </a>
          </div>
          <div className="row justify-content-between  m-0 p-0 align-items-center">
            <div className="col-xl-9 d-flex justify-content-around legales-res">
              <p className="legal">
                <span className="legales">
                  {" "}
                  <b>Aviso legal </b>
                </span>{" "}
                <br />
                Lo invitamos a disfrutar del contenido a continuación teniendo
                en cuenta que está sujeto a derechos de propiedad intelectual.
                Este material podría contener conceptos u opiniones que son
                responsabilidad de los autores y no comprometen las opiniones
                del laboratorio auspiciante. Boehringer Ingelheim S.A. Teléfono:
                (+601) 514 14 40, e-mail: medfora.co@boehringer-ingelheim.com
                Dirección: Avenida Calle 116 # 7 - 15, Oficina 1401, Piso 14.
                PC-CO-104088.
              </p>
            </div>
            <div
              className="col-xl-3 legales-res1"
              style={{
                maxWidth: 289,
              }}
            >
              <p
                className={
                  changeColor ? "legal1 mt-4 light-blue" : "legal1 mt-4"
                }
              >
                <span className="legales">
                  {" "}
                  <b>CONTÁCTENOS: </b>
                </span>
                <br />
                Boehringer Ingelheim S.A.
                <br />
                Teléfono: (+601) 514 14 40
                <br />
                e-mail: medfora.co@boehringer-ingelheim.com
                <br />
                Dirección: Avenida Calle 116 # 7 - 15, Oficina 1401, Piso 14
                <br />
                Bogotá D.C. Colombia
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="brands p-3">
        <div className="container">
          <div className="row logos-resp m-auto p-0 align-items-center">
            <div className="col-lg-9 col-sm-12 m-0 p-0 boehringer-res">
              <img
                src="/images/footer2.png"
                className="img-fluid logotermino"
                alt="Logos compañías asociadas"
                style={{
                  maxWidth: "92%",
                  marginLeft: "30px",
                  mixBlendMode: "multiply",
                }}
              />
            </div>

            <div className="col-lg-3 col-sm-12 m-0 p-0 terminos-resp">
              <a
                className="terminos"
                href={`${baseApiUrl}/docs/terminos-y-condiciones.pdf`}
              >
                Términos y condiciones
              </a>
              <a
                className="terminos"
                href={`${baseApiUrl}/docs/politica-cookies.pdf`}
              >
                Política de cookies
              </a>
              <a
                className="terminos"
                href={`${baseApiUrl}/docs/politica-tratamiento-datos.pdf`}
              >
                Privacidad de datos
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          changeColor ? "row m-0 class-copy light-blue" : "row m-0 class-copy"
        }
      >
        <div className="col-12 m-0  text-center">
          <p className="text-fin">
            © 2010-{new Date().getFullYear()} Boehringer Ingelheim GmbH Todos
            los derechos Reservados
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
