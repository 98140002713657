import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BreadCrumbsMeds = ({
  homeUrl,
  section,
  type,
  subsection,
  typeUrl = null,
  media = null,
}) => {
  return (
    <div className="flex gap-[10px] breadcrumbs pb-[10px]">
      <Link to={homeUrl}>
        <img src="/images/icon-house.png" alt="home" width={22} height={24} />
      </Link>
      <p>{">"}</p>
      <p>{section}</p>
      <p>
        {subsection ? (
          <p>
            {"> "}
            {subsection === "generalidades" ? "Generalidades y Curiosidades": ""}
            {subsection === "vcle" ? "Vivir con la enfermedad": ""}
            {subsection === "diagnostico" ? "Diagnóstico": ""}
            {subsection === "prevencion" ? "Prevención": ""}
            {subsection === "otros" ? "Otros": ""}
          </p>
        ) : (
          ""
        )}{" "}
      </p>
      {type && <p>{">"}</p>}
      {media ? (
        <>
          <Link to={typeUrl}>
            <p style={{ textTransform: "capitalize" }}>{type}</p>
          </Link>
          <p>{">"}</p>
          <p>{media}</p>
        </>
      ) : (
        <p style={{ textTransform: "capitalize" }}>{type}</p>
      )}
    </div>
  );
};

BreadCrumbsMeds.propTypes = {
  homeUrl: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  type: PropTypes.string,
  typeUrl: PropTypes.string,
  media: PropTypes.string,
};

export default BreadCrumbsMeds;
