import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savedMediasStartLoading } from "../../../../actions/medias";
import { baseApiUrl } from "../../../../helpers/global";
import BreadCrumbs from "../../../layouts/breadCrumbs/BreadCrumbs";
import SimpleMediaCard from "../../../layouts/cards/SimpleMediaCard";
import Loader from "../../../layouts/loader/Loader";
import SectionHeader from "../../../layouts/sectionHeader/SectionHeader";

import MenuMeds from "../../../layouts/header/menu/MenuMeds";
import "./SavedMedias.styles.scss";

const SavedMediasScreen = () => {
  const dispatch = useDispatch();

  const { savedMedias } = useSelector((state) => state.medias);
  const { savedMediasLoading } = useSelector((state) => state.ui);

  const [showAll, setShowAll] = useState(false);

  // ? Obtener todas las medias guardadas
  const getSavedMedias = useCallback(() => {
    if (savedMedias.length === 0) dispatch(savedMediasStartLoading());
  }, [dispatch, savedMedias.length]);

  // ? Mostrar listado de medias (todas o solo las 3 primeras)
  const renderMedias = () => {
    if (savedMedias.length === 0)
      return (
        <div className="col-md-6">
          <h5>No tienes materiales guardados</h5>
        </div>
      );

    const mediasToShow = showAll ? savedMedias : savedMedias?.slice(0, 6);

    return mediasToShow?.map((media) => (
      <SimpleMediaCard
        key={media.id}
        thumb={`${baseApiUrl}/${media.thumbnail}`}
        name={media.name}
        description={media.description}
        url={`${media.section_slug}/${media.type_slug}/${media.media_slug}`}
      />
    ));
  };

  useEffect(() => {
    getSavedMedias();
  }, [getSavedMedias]);

  return (
    <div>
      <MenuMeds />

      {/* BANNER */}
      <SectionHeader
        customClass="header-content bg-section-medicos-image"
        sectionName="Favoritos"
        sectionDescription="En esta sección encontrará una lista con todos los contenidos de su interés"
      />

      <div className="container mx-auto font-helvetica-normal savedMedias">
        <div>
          {/* Contenido Favorito */}
          <div>
            <h4 className="text-[#048abf] text-4xl pt-5 font-helvetica-normal font-bold">
              Contenido de su interés
            </h4>

            {/* CONTENIDO DE INTERÉS */}
            <div className="row">
              {savedMediasLoading ? <Loader /> : renderMedias()}
            </div>
          </div>
          {/* Botón "Cargar Más" */}
          <div className="flex justify-center mt-[20px] mb-[40px]">
            {!savedMediasLoading && !showAll && savedMedias.length > 6 && (
              <button
                className="bg-[#048abf] w-[151px] h-[36px] flex justify-center items-center pt-[4px] rounded-[20px] text-white font-helvetica-bold text-[16px] leading-[19px]"
                onClick={() => setShowAll(true)}
              >
                Cargar mas +
              </button>
            )}
          </div>
          {/* Botón Favoritos */}
          <BreadCrumbs
            homeUrl="/para-medicos/sobre-sospechar-no-falla"
            section="Favoritos"
          />
        </div>
      </div>
    </div>
  );
};

export default SavedMediasScreen;
