import React, { useState } from "react";
import { routesPatients } from "../../menu-items";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProfileMenu from "../../ProfileMenu";

import "./Drawer.styles.scss";
import {
  uiPageFinishLoading,
  uiPageStartLoading,
} from "../../../../../../actions/ui";

const DrawerPatients = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const { sections } = useSelector((state) => state.ui);

  const [menuProfile, setMenuProfile] = useState(false);
  const [subMenu, setSubmenu] = React.useState({});
  const [isMobileSubMenuOpen, setMobileSubMenuOpen] = useState(null);

  const isRoute = (path) => pathname.includes(path);

  const handleMobileSubMenuClick = (index) => {
    setMobileSubMenuOpen((prevIndex) =>
      prevIndex === index ? prevIndex : index
    );
  };

  const getObject = (type) => {
    const route = routesPatients(sections, currentSection).find(
      (item) => item.name === type
    );
    return subMenu.name === type ? { open: false } : { open: true, ...route };
  };

  const handleMenuItemAction = (slug, name) => {
    if (name === "Blogs") {
      navigate(`/${currentSection}/blogs/blogs`);
    } else {
      setSubmenu(getObject(slug));
    }
  };

  const handleLoadPage = () => {
    setSubmenu({ open: false });
    dispatch(uiPageStartLoading());
    setTimeout(() => dispatch(uiPageFinishLoading()), 100);
  };

  const renderProfileMenuItem = () => (
    <li
      className={`profile-drawer nav-link ${
        menuProfile ? "bg-[#d7d8da] text-black" : "bg-[#2a4252] text-white"
      }`}
      onClick={() => setMenuProfile(!menuProfile)}
    >
      <div className={`profile-btn${menuProfile ? " mb-3" : ""}`}>
        <img src="/images/logo-user.png" alt="Logo perfil" />
        <span>Perfil</span>
      </div>
      {menuProfile && <ProfileMenu />}
    </li>
  );

  return (
    <div
      className={`drawer ${open ? "block" : "hidden"}`}
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal={open}
    >
      <div className="overlay"></div>
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto w-screen max-w-md">
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 overflow-y-hidden px-4 py-6 sm:px-6 main-drawer">
                  <div className="flex items-center justify-between">
                    <img
                      src="/images/logo-gris.png"
                      alt="Logo sospechar no falla"
                      className="logo"
                    />
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        onClick={onClose}
                        type="button"
                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Close panel</span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="mt-8">
                    <ul>
                      {currentSection === "para-medicos" &&
                        renderProfileMenuItem()}
                      <li
                        className={`nav-item active ${
                          isRoute(
                            currentSection === "para-todos"
                              ? "/para-todos/inicio"
                              : "/para-medicos/sobre-sospechar-no-falla"
                          )
                            ? "web border-t-[1px]"
                            : "border-t-[1px]"
                        }`}
                      >
                        <Link
                          to={
                            currentSection === "para-todos"
                              ? "/para-todos/inicio"
                              : "/para-medicos/sobre-sospechar-no-falla"
                          }
                          className="nav-link"
                        >
                          {currentSection === "para-todos"
                            ? "Inicio"
                            : "Sobre Sospechar No Falla"}
                        </Link>
                      </li>
                      {sections?.map((section) => (
                        <li
                          key={section.id}
                          className={`nav-item active ${
                            isRoute(`/${currentSection}/${section.slug}`)
                              ? "web"
                              : ""
                          }`}
                        >
                          <span
                            className="nav-link text-[#034aa6]"
                            onClick={() =>
                              handleMenuItemAction(section.slug, section.name)
                            }
                          >
                            {section.name}
                          </span>
                          <div
                            className="menu"
                            style={{
                              display:
                                subMenu.open && subMenu.name === section.slug
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="header">
                              <h1 className="title">{subMenu.title}</h1>
                              <h2 className="description">
                                {subMenu.description}
                              </h2>
                            </div>
                            <div className="links">
                              {subMenu.items &&
                                subMenu.items.map((it, index) => (
                                  <div key={index} className="mobile-menu-item">
                                    <div
                                      className="item hover:underline hover:cursor-pointer py-[10px] text-[#034aa6] pl-[20px] font-helvetica-bold"
                                      onClick={() =>
                                        handleMobileSubMenuClick(index)
                                      }
                                    >
                                      {it.name} {">"}
                                    </div>
                                    {isMobileSubMenuOpen === index && (
                                      <div>
                                        <ul>
                                          {it.multimedia_types.map(
                                            (tipos, tiposIndex) => (
                                              <Link
                                                key={tiposIndex}
                                                className="flex flex-col py-1.5 text-xl font-helvetica-bold text-[#034aa6] hover:underline hover:cursor-pointer"
                                                to={tipos.path}
                                                onClick={handleLoadPage}
                                              >
                                                {tipos.name}
                                              </Link>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerPatients;
