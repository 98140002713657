import { freeFetch, tokenFetch } from "../helpers/fetch";
import { types } from "../types/types";

// ? Obtener todos los tipos de profesionales
export const professionsStartLoading = () => {
  return async (dispatch) => {
    try {
      dispatch(uiProfessionsStartLoading());
      const resp = await freeFetch("UI/getProfessionalTypes");
      const data = await resp.json();

      if (data.status) {
        dispatch(professionsLoaded(data.professions));
      }

      dispatch(uiProfessionsFinishLoading());
    } catch (error) {
      console.log(error);
    }
  };
};

const uiProfessionsStartLoading = () => ({
  type: types.uiProfessionsStartLoading,
});
const uiProfessionsFinishLoading = () => ({
  type: types.uiProfessionsFinishLoading,
});

const professionsLoaded = (professions) => ({
  type: types.uiProfessionsLoaded,
  payload: professions,
});

// ? Obtener todos los tipos de expecialidades
export const specialtiesStartLoading = () => {
  return async (dispatch) => {
    try {
      dispatch(uiSpecialtiesStartLoading());
      const resp = await freeFetch("UI/getEspecialistTypes");
      const data = await resp.json();

      if (data.status) {
        dispatch(specialtiesLoaded(data.specialties));
      }

      dispatch(uiSpecialtiesFinishLoading());
    } catch (error) {
      console.log(error);
    }
  };
};

// ? Obtener todas las secciones
export const sectionsStartLoading = ({ professional }) => {
  return async (dispatch) => {
    try {
      dispatch(uiSectionsStartLoading());

      const resp = professional
        ? await tokenFetch("UI/getProfessionalSections")
        : await freeFetch("UI/getPatientsSections");

      const data = await resp.json();

      if (data.status) {
        dispatch(sectionsLoaded(data.sections));
      } else {
        dispatch(sectionsLoaded([]));
      }

      dispatch(uiSectionsFinishLoading());
    } catch (error) {
      console.log(error);
    }
  };
};

const uiSpecialtiesStartLoading = () => ({
  type: types.uiSpecialtiesStartLoading,
});
const uiSpecialtiesFinishLoading = () => ({
  type: types.uiSpecialtiesFinishLoading,
});

const specialtiesLoaded = (specialties) => ({
  type: types.uiSpecialtiesLoaded,
  payload: specialties,
});

const uiSectionsStartLoading = () => ({
  type: types.uiSectionsStartLoading,
});
const uiSectionsFinishLoading = () => ({
  type: types.uiSectionsFinishLoading,
});

const sectionsLoaded = (sections) => ({
  type: types.uiSectionsLoaded,
  payload: sections,
});

// ? Carga de medias
export const uiAuthStartLoginLoading = () => ({
  type: types.uiAuthStartLoginLoading,
});
export const uiAuthFinishLoginLoading = (message) => ({
  type: types.uiAuthFinishLoginLoading,
  payload: message,
});
export const uiAuthStartRegisterLoading = () => ({
  type: types.uiAuthStartRegisterLoading,
});
export const uiAuthFinishRegisterLoading = (message) => ({
  type: types.uiAuthFinishRegisterLoading,
  payload: message,
});
export const uiUserDataUpdateStartLoading = () => ({
  type: types.uiUserDataUpdateStartLoading,
});
export const uiUserDataUpdateFinishLoading = (message) => ({
  type: types.uiUserDataUpdateFinishLoading,
  payload: message,
});
export const uiChangePasswordStartLoading = () => ({
  type: types.uiChangePasswordStartLoading,
});
export const uiChangePasswordFinishtLoading = (message) => ({
  type: types.uiChangePasswordFinishtLoading,
  payload: message,
});
export const uiClearMessages = () => ({
  type: types.uiClearMessages,
});

export const uiOpenUpdateModal = () => ({
  type: types.uiOpenUpdateModal,
});
export const uiCloseUpdateModal = () => ({
  type: types.uiCloseUpdateModal,
});

export const uiOpenPicModal = () => ({
  type: types.uiOpenPicModal,
});
export const uiClosePicModal = () => ({
  type: types.uiClosePicModal,
});

export const uiLastSocialMediasStartLoading = () => ({
  type: types.uiLastSocialMediasStartLoading,
});
export const uiLastSocialMediasFinishLoading = () => ({
  type: types.uiLastSocialMediasFinishLoading,
});

export const uiLastMediasStartLoading = () => ({
  type: types.uiLastMediasStartLoading,
});
export const uiLastMediasFinishLoading = () => ({
  type: types.uiLastMediasFinishLoading,
});

export const uiMediasStartLoading = () => ({
  type: types.uiMediasStartLoading,
});
export const uiMediasFinishLoading = () => ({
  type: types.uiMediasFinishLoading,
});

export const uiMediaDataStartLoading = () => ({
  type: types.uiMediaDataStartLoading,
});
export const uiMediaDataFinishLoading = () => ({
  type: types.uiMediaDataFinishLoading,
});

export const uiMediasHistoryStartLoading = () => ({
  type: types.uiMediasHistoryStartLoading,
});
export const uiMediasHistoryFinishLoading = () => ({
  type: types.uiMediasHistoryFinishLoading,
});

export const uiRelatedMediasStartLoading = () => ({
  type: types.uiRelatedMediasStartLoading,
});
export const uiRelatedMediasFinishLoading = () => ({
  type: types.uiRelatedMediasFinishLoading,
});

export const uiMediasSavedStartLoading = () => ({
  type: types.uiMediasSavedStartLoading,
});
export const uiMediasSavedFinishLoading = () => ({
  type: types.uiMediasSavedFinishLoading,
});

export const uiPageStartLoading = () => ({
  type: types.uiPageStartLoading,
});
export const uiPageFinishLoading = () => ({
  type: types.uiPageFinishLoading,
});
