import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { baseApiUrl, baseFrontUrl } from "../../../helpers/global";
import Toolbar from "../toolBar/Toolbar";

import "./PodcastPlayer.styles.scss"

const PodcastPlayer = () => {
  
  const { pathname } = useLocation();
  const currentSection = pathname.split('/')[1];

  const { mediaData } = useSelector(state => state.medias);

  const [audio, setAudio] = useState(null);
  const [isPlay, setIsPlay] = useState(false);

  const progressBarRef = useRef(null);
  const timeBarRef = useRef(null);

  const playAudio = () => {
    const newAudio = new Audio(`${baseApiUrl}/${mediaData?.file_path}`)
    const playingAudio = newAudio.play();

    if (playingAudio !== undefined) {
      playingAudio.then(function () {
        setAudio(newAudio);
        setIsPlay(true);

        newAudio.addEventListener('timeupdate', function () {
          if (progressBarRef.current)
            progressBarRef.current.style.width = newAudio.currentTime / newAudio.duration * 100 + "%";
        });

        newAudio.addEventListener('ended', () => setIsPlay(false));

      }).catch(function (error) {
        console.log(error)
      });
    }
  };

  const pauseAudio = () => {
    audio.pause();
    setIsPlay(false);
  };

  useEffect(() => {
    return () => {
      if (audio) audio.pause();
    }
  }, [audio]);

  return (
    <>
      <div  className='w-full podcats-container'>
        <div className="container-podcast">
          <div>
            <div className="flex items-center podcast-info">
              <div className="flex play-btn">
                {
                    !isPlay ? <img src="/images/play.png" alt="" width={75} className="cursor-pointer" onClick={playAudio} />
                        : <img src="/images/pause.png" alt="" width={75} className="cursor-pointer" onClick={pauseAudio} />
                }
              </div>
              <div className="pl-[40px] container-title">
                <h2 className="text-white text-[22px] leading-[26px] font-helvetica-bold pb-[40px]">
                  {mediaData?.name}
                </h2>
                <p className="text-white text-[16px] leading-[19px] font-helvetica-normal">
                  {mediaData?.description}
                </p>
              </div>
            </div>
          </div>
          <div ref={timeBarRef} className='timeline mt-[40px]'>
            <img src="/images/waves.png" alt="Reproductor" className="w-full z-10 relative" />
            <div ref={progressBarRef} className={currentSection === 'para-pacientes' ? 'progress bg-[red]' : 'progress bg-[#739cde]'}></div>
          </div>
        </div>
        <div className="podcats-img">
          <img src={`${baseApiUrl}/${mediaData?.main_pic}`} alt={mediaData?.name} />
        </div>
      </div>
      <Toolbar url={`${baseFrontUrl}${pathname}`} type="mp3" />
    </>
  );
};

export default PodcastPlayer;
