import React from "react";
import ReactGA from 'react-ga4';
import PropTypes from "prop-types";
import { handleCopyToClipboard } from "../../../helpers/copyToClipboard";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSavedMediaStartLoading,
  likeStartLoading,
  saveMediaStartLoading,
  unlikeStartLoading,
} from "../../../actions/medias";
import { baseApiUrl } from "../../../helpers/global";

const Toolbar = ({ url, type }) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];

  const { mediaData, savedMedias } = useSelector((state) => state.medias);

  // ? Registrar evento de usuario
  const registerEvent = ({ action, label }) => {
    ReactGA.event({
      category: 'Interacción de usuario',
      action,
      label
    });
  };

  // ? Dar like a material
  const handleLike = () => {
    if (mediaData.liked) {
      dispatch(unlikeStartLoading({ id: mediaData.id }));
    } else {
      dispatch(likeStartLoading({ id: mediaData.id }));

      registerEvent({ action: 'Like', label: 'Han dado like a un material' });
    }
  };

  // ? Guardar material
  const handleSave = () => {
    // Verificar si ya hay elementos guardados
    const areSaved = savedMedias.length > 0;
    const mediaToSave = {
      id: mediaData.id,
      name: mediaData.name,
      description: mediaData.description,
      content: mediaData.content,
      thumbnail: mediaData.thumbnail,
      type: mediaData.type,
      media_slug: mediaData.media_slug,
      section_slug: mediaData.section_slug,
      type_slug: mediaData.type_slug,
      available: mediaData.available,
    };

    if (mediaData.saved) {
      dispatch(deleteSavedMediaStartLoading({ id: mediaData.id, areSaved }));
    } else {
      dispatch(
        saveMediaStartLoading({ id: mediaData.id, areSaved, mediaToSave })
      );

      registerEvent({ action: 'Favorito', label: 'Material marcado como favorito' });
    }
  };

  return (
    <div className="shared">
      <div className="share" onClick={() => {
        handleCopyToClipboard(url);
        registerEvent({ action: 'Copiado', label: 'Link de material copiado' })
      }}>
        <img
          src="/images/share-icon.png"
          alt="Compartir material"
          width={18}
          height={22}
        />
        <p>Copiar Link</p>
      </div>

      {currentSection !== "para-pacientes" && (
        <>
          <div className="share" onClick={handleSave}>
            <img
              src={
                mediaData?.saved
                  ? "/images/favorite-active.png"
                  : "/images/favorite.png"
              }
              alt="Guardar"
              width={18}
              height={22}
            />
            <p>{mediaData?.saved ? "Eliminar" : "Favoritos"}</p>
          </div>
          <div className="share" onClick={handleLike}>
            <img
              src={
                mediaData?.liked
                  ? "/images/gusta-active.png"
                  : "/images/gusta.png"
              }
              alt="Me gusta"
              width={18}
              height={22}
            />
            <p>Me gusta</p>
          </div>
        </>
      )}

      <a
        className="share"
        href={`${baseApiUrl}/Medias/downloadFile?path=${mediaData?.file_path}&type=${type}`}
        onClick={() => registerEvent({ action: 'Download', label: 'Material descargado' })}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/images/download.png"
          alt="Descargar"
          width={18}
          height={22}
        />
        <p>Descargar</p>
      </a>
    </div>
  );
};

Toolbar.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["jpg", "mp3", "mp4"]).isRequired,
};

export default Toolbar;
