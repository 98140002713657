import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { allMediasStartLoading } from "../../../../actions/medias";
import { baseApiUrl } from "../../../../helpers/global";
import MediaCard from "../../../layouts/cards/MediaCard";
import SimpleMediaCard from "../../../layouts/cards/SimpleMediaCard";
import Loader from "../../../layouts/loader/Loader";
import BreadCrumbs from "../../../layouts/breadCrumbs/BreadCrumbs";
import SectionHeader from "../../../layouts/sectionHeader/SectionHeader";

import "./sectionScreen.styles.scss";
import MenuMeds from "../../../layouts/header/menu/MenuMeds";

const SectionScreenMedicos = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];

  const { section, type } = useParams();

  const { mediasLoading, sections, pageLoading } = useSelector(
    (state) => state.ui
  );
  const { medias, history } = useSelector((state) => state.medias);

  const [showAll, setShowAll] = useState(false);

  // ? Obtener listado de medias de esta seccion
  const getMediasData = useCallback(() => {
    if (currentSection === "para-medicos")
      dispatch(allMediasStartLoading({ section, type, professional: true }));
    if (currentSection === "para-pacientes")
      dispatch(allMediasStartLoading({ section, type, professional: false }));
  }, [dispatch, currentSection, section, type]);

  // ? Mostrar listado de medias (todas o solo las 3 primeras)
  const renderMedias = () => {
    if (!medias || medias.length === 0)
      return (
        <div className="col-md-6">
          <h5>No hay materiales en esta sección</h5>
        </div>
      );

    const mediasToShow = showAll ? medias : medias.slice(0, 3);

    return mediasToShow?.map((media) => (
      <SimpleMediaCard
        key={media.id}
        thumb={`${baseApiUrl}/${media.thumbnail}`}
        name={media.name}
        description={media.description}
        url={
          media.url
            ? media.url
            : `${media.section_slug}/${media.type_slug}/${media.media_slug}`
        }
        external={!!media.url}
      />
    ));
  };

  // ? Mostrar historial
  const renderHistory = () => {
    let historyToRender = pathname.includes("para-medicos")
      ? history
      : medias?.slice(0, 2);

    if (historyToRender.length === 0)
      return (
        <div className="col-md-6">
          <h5>Aún no has visto ningún material</h5>
        </div>
      );

    return historyToRender?.map((media, key) => (
      <MediaCard
        key={key}
        thumb={`${baseApiUrl}/${media.thumbnail}`}
        name={media.name}
        description={media.description}
        url={
          media.url
            ? media.url
            : `${media.section_slug}/${media.type_slug}/${media.media_slug}`
        }
        external={!!media.url}
      />
    ));
  };

  // ? Obtener nombre de la sección
  const currentSectionItem = sections?.filter(
    (item) => item.slug === section
  )[0];
  const sectionName = currentSectionItem ? currentSectionItem.name : "Ups! 404";
  const sectionDescription = currentSectionItem
    ? currentSectionItem.description
    : "Esta sección no existe";

  // ? Obtener nombre del tipo de multimedia
  const getMediaTypeTexts = () => {
    const mediaTypes = {
      infografias: {
        name: "infografías",
        subsection: "infografía",
        title: "Últimas infografías",
        text: "¿Deseas ver de nuevo alguna infografía? Encuéntrala en este historial.",
      },
      videos: {
        name: "videos",
        title: "Últimos videos",
        text: "¿Quieres volver a ver algún video al que accediste recientemente? ¡Encuéntralo en este historial!",
      },
      podcasts: {
        name: "pódcasts",
        title: "Últimos pódcasts",
        text: "¿Deseas escuchar de nuevo algún pódcast? Encuéntralo en este historial.",
      },
      tests: {
        name: "tests",
        title: "Últimos test",
        text: "¿Quieres volver a poner a prueba tus conocimientos sobre falla cardíaca? ¡Resuelve de nuevo los test a los que ya accediste y conviértete en un detective estrella!",
      },
      blogs: {
        name: "blogs",
        title: "Últimos blogs",
        text: "¿Deseas leer de nuevo algún blog? Encuéntralo en este historial.",
      },
      otros: {
        name: "otros",
        title: "Sección en construcción",
        text: "Sección en construcción",
      },
      posters: {
        name: "pósters",
        title: "Últimos pósters",
        text: "¿Deseas ver de nuevo algún póster? Encuéntralo en este historial.",
      },
    };

    return mediaTypes[type] || null;
  };

  const mediaTypeData = getMediaTypeTexts();

  useEffect(() => {
    getMediasData();
    window.scroll({ top: 0 });
    setShowAll(false);
  }, [getMediasData]);

  return (
    <div className="generalidades">
      <MenuMeds />

      <SectionHeader
        customClass={
          currentSection === "para-pacientes"
            ? "header-content bg-section-pacientes-image"
            : "header-content bg-section-medicos-image"
        }
        sectionName={sectionName}
        sectionDescription={sectionDescription}
        loading={pageLoading}
      />

      {currentSection && mediaTypeData ? (
        <div className="infografias">
          <div className="container">
            <h2>{mediaTypeData?.title}</h2>
            <h5>{mediaTypeData?.text}</h5>

            <div className="row">
              {mediasLoading ? <Loader /> : renderHistory()}
            </div>

            <h2 className="mt-[60px]">
              <span style={{ textTransform: "capitalize" }}>
                {mediaTypeData?.name}
              </span>{" "}
              anteriores
            </h2>

            <h5>
              Accede a{" "}
              {mediaTypeData?.name === "infografías" ? "todas" : "todos"}{" "}
              {mediaTypeData?.name === "infografías" ? "las" : "los"}{" "}
              {mediaTypeData?.name} con información seleccionada especialmente
              para ti.
            </h5>

            <div className="row">
              {mediasLoading ? <Loader /> : renderMedias()}
            </div>
            <div className="flex justify-center mt-[20px] mb-[40px]">
              {!mediasLoading && !showAll && medias.length > 3 && (
                <button
                  className="bg-[#048abf] w-[151px] h-[36px] flex justify-center items-center pt-[4px] rounded-[20px] text-white font-helvetica-bold text-[16px] leading-[19px]"
                  onClick={() => setShowAll(true)}
                >
                  Cargar mas +
                </button>
              )}
            </div>
            <BreadCrumbs
              homeUrl={
                pathname.includes("para-medicos")
                  ? "/para-medicos/sobre-sospechar-no-falla"
                  : "/para-pacientes/inicio"
              }
              section={sectionName}
              type={mediaTypeData?.name}
            />
          </div>
        </div>
      ) : (
        <div className="infografias pb-[40px]">
          <div className="container">
            <h2>Lo sentimos</h2>
            <h5>
              Pero la sección o material al cual intentas acceder, no existe
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionScreenMedicos;
