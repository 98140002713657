import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userStartLogin} from "../../../../actions/auth";
import {useForm} from "../../../../hooks/UseForm";
import {motion} from "framer-motion";
import {fadeIn} from "../../../../helpers/variants";
import {uiClearMessages} from "../../../../actions/ui";
import Cookies from "universal-cookie";

import "./Login.styles.scss";
import InfoModal from "./InfoModal";

const cookies = new Cookies();

const initialForm = {
    email: cookies.get("email", {path: "/"})
        ? cookies.get("email", {path: "/"})
        : "",
    password: cookies.get("pass", {path: "/"})
        ? cookies.get("pass", {path: "/"})
        : "",
};

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {loginMessage, loginLoading} = useSelector((state) => state.ui);

    const [forgot, setForgot] = useState(false);
    const [remember, setRemember] = useState(false);

    const [formValues, handleInputChange] = useForm(initialForm);
    const {email, password} = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(userStartLogin({email, password, remember}));
    };

    useEffect(() => {
        dispatch(uiClearMessages());
    }, [dispatch]);

    return (
        <div className="login-container">
            <div className="bg-blue">
                <div
                    className="container"
                    style={{width: "100%", position: "relative", height: "100%"}}
                >
                    <header className="header">
                        <img
                            src="/images/logo.png"
                            alt="Sospechar no falla logo"
                            className="logo cursor-pointer"
                            onClick={() => navigate("/")}
                        />
                        <div
                            className="col-md-6 d-flex align-items-center justify-content-end text-right m-0 p-0 logos-res">
                            <p className="m-0 mr-2 siguenos text-white">Siguenos:</p>
                            <a
                                href="https://www.facebook.com/sospecharnofalla"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/images/fece-b.png"
                                    className="img-fluid mr-2"
                                    alt="Facebook"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/sospechar_nofalla/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/images/insta-b.png"
                                    className="img-fluid mr-2"
                                    alt="Instagram"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/sospechar_nofalla/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="/images/tik-b.png"
                                    className="img-fluid mr-2"
                                    alt="Tik tok"
                                />
                            </a>
                        </div>
                    </header>
                    {/** cartas */}
                    <div className="content ">
                        <div className="fila">
                            <div className="col-xl-5">
                                <div className="card-content">
                                    <motion.div
                                        variants={fadeIn("right", 0.1)}
                                        initial="hidden"
                                        whileInView={"show"}
                                        viewport={{once: true, amount: 0.3}}
                                        className="card card-res-tam"
                                        style={{
                                            width: 330,
                                            backgroundColor: "#ffff",
                                        }}
                                    >
                                        <div className="card-body">
                                            <h5 className="bienvenido mt-2 sospechar-res">
                                                Bienvenido
                                            </h5>
                                            <p className="card-text texto1 mb-3 text-sitio-res">
                                                Este sitio web está destinado para profesionales de la
                                                salud como tú
                                            </p>
                                            <button
                                                className="btn btn-primary btn-registra-res"
                                                onClick={() => navigate("/para-medicos/registrarse")}
                                            >
                                                ¿Primera vez? Regístrate aquí{" "}
                                                <img
                                                    src="/images/flecha-white.png"
                                                    className="d-inline-block"
                                                    alt="flecha logo"
                                                />
                                            </button>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                            <div className="col-xl-7">
                                <motion.div
                                    variants={fadeIn("left", 0.1)}
                                    initial="hidden"
                                    whileInView={"show"}
                                    viewport={{once: true, amount: 0.3}}
                                    className="class-cartas"
                                >
                                    <div
                                        className="card card-ingrsa card-aqui"
                                        style={{width: 330, height: 408}}
                                    >
                                        <div className="card-body">
                                            <h5 className="card-title title-form1">Ingresa aqui</h5>

                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label
                                                        className="text-label"
                                                        htmlFor="exampleInputEmail1"
                                                    >
                                                        Correo electronico*
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleInputChange}
                                                        className="form-control border border-primary"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="exampleInputPassword1"
                                                        className="text-label"
                                                    >
                                                        Contraseña*
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={handleInputChange}
                                                        className="form-control border border-primary"
                                                        id="exampleInputPassword1"
                                                        required
                                                    />
                                                </div>

                                                {loginMessage && (
                                                    <p className="error-message">{loginMessage}*</p>
                                                )}

                                                <button
                                                    type="submit"
                                                    className={
                                                        loginLoading
                                                            ? "btn btn-primary mt-3 disabled"
                                                            : "btn btn-primary mt-3 entrar"
                                                    }
                                                >
                                                    {loginLoading ? (
                                                        "Entrando..."
                                                    ) : (
                                                        <>
                                                            Entrar
                                                            <img
                                                                src="/images/flecha-white2.png"
                                                                className="d-inline-block"
                                                                alt="flecha logo"
                                                                style={{marginLeft: 10}}
                                                            />
                                                        </>
                                                    )}
                                                </button>
                                                <div className="mt-3">
                                                    <div
                                                        className="ancla1 cursor-pointer"
                                                        onClick={() => setForgot(true)}
                                                    >
                                                        <img
                                                            src="/images/flecha-azul.png"
                                                            className="d-inline-block"
                                                            alt="flecha logo"
                                                            style={{marginLeft: 4}}
                                                        />{" "}
                                                        Olvide mi contraseña
                                                    </div>
                                                </div>
                                                <div className="radio ">
                                                    <input
                                                        type="checkbox"
                                                        id="html"
                                                        name="fav_language"
                                                        value={remember}
                                                        onChange={() => setRemember(!remember)}
                                                    />
                                                    <label
                                                        className="ancla1"
                                                        htmlFor="html"
                                                        style={{marginLeft: 3}}
                                                    >
                                                        Recordarme
                                                    </label>
                                                    <br/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {forgot && <InfoModal setForgot={setForgot}/>}
        </div>
    );
};

export default Login;
