import React, { useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sectionsStartLoading } from "../actions/ui";
import SectionScreen from "../components/screens/common/SectionScreen/SectionScreen";
import MediaScreen from "../components/screens/common/mediaScreen/MediaScreen";
import Principal from "../components/screens/common/home/Home";

const PatientsRoutes = () => {
  const dispatch = useDispatch();

  // ? Obtener secciones de pacientes
  const getSectionsData = useCallback(() => {
    dispatch(sectionsStartLoading({ professional: false }));
  }, [dispatch]);

  useEffect(() => {
    getSectionsData();
  }, [getSectionsData]);

  return (
    <>
      <Routes>
        <Route path="inicio" element={<Principal />} />
        <Route path=":section/:subsection/:type/" element={<SectionScreen />} />
        <Route path=":section/:subsection/:type/:media" element={<MediaScreen />}/>
        <Route path="*" element={<h1>No existe</h1>} />
      </Routes>
    </>
  );
};

export default PatientsRoutes;
