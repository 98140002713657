import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AppRouter from './routers/AppRouter';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-Y6JMW64VXG';

const SnfApp = () => {

    ReactGA.initialize(TRACKING_ID);

    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    );
};

export default SnfApp;