import { types } from "../types/types";

const initialState = {
  mediaData: null,
  lastMedias: [],
  lastSocialMedias: [],
  medias: [],
  history: [],
  relatedMedias: [],
  savedMedias: [],
};

export const mediasReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.mediaDataLoaded:
      return {
        ...state,
        mediaData: action.payload,
      };

    case types.lastSocialmediasLoaded:
      return {
        ...state,
        lastSocialMedias: action.payload,
      };

    case types.lastmediasLoaded:
      return {
        ...state,
        lastMedias: action.payload,
      };

    case types.mediasLoaded:
      return {
        ...state,
        medias: action.payload,
      };

    case types.mediasHistoryLoaded:
      return {
        ...state,
        history: action.payload,
      };

    case types.relatedmediasLoaded:
      return {
        ...state,
        relatedMedias: action.payload,
      };

    case types.mediasSavedLoaded:
      return {
        ...state,
        savedMedias: action.payload,
      };

    case types.mediasSavedAddNew:
      return {
        ...state,
        savedMedias: [...state.savedMedias, action.payload],
      };

    case types.mediasSavedDelete:
      return {
        ...state,
        savedMedias: state.savedMedias.filter(
          (media) => media.id !== action.payload
        ),
      };

    case types.mediaUpdated:
      return {
        ...state,
        mediaData: { ...state.mediaData, ...action.payload },
      };

    default:
      return state;
  }
};
