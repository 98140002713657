export const routes = (sections, current) => {
  const routes = [];

  sections.forEach((section) => {
    routes.push({
      name: section.slug,
      title: section.name,
      description: section.description,
      items: section.items.map((item) => ({
        name: item.name,
        path: `/${current}/${section.slug}/${item.slug}`,
      })),
    });
  });

  return routes;
};

export const routesPatients = (sections, current) => {
  const routes = [];
  sections.forEach((section) => {
    routes.push({
      name: section.slug,
      title: section.name,
      description: section.description,
      items: section.items.map((item) => ({
        name: item.name,
        multimedia_types: item.tiposMultimedia
          ? item.tiposMultimedia.map((subseccion) => ({
              name: subseccion.name,
              path: `/${current}/${section.slug}/${item.slug}/${subseccion.slug}`,
            }))
          : [],
      })),
    });
  });
  return routes;
};
