import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProfileMenu from "../../ProfileMenu";
import { routes } from "../../menu-items";

import "./Drawer.styles.scss";

const Drawer = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const { sections } = useSelector((state) => state.ui);
  const [menuProfile, setMenuProfile] = useState(false);
  const [subMenu, setSubmenu] = useState({});

  const isRoute = (path) => {
    return pathname.includes(path);
  };

  const getObject = (type) => {
    const route = routes(sections, currentSection).find(
      (item) => item.name === type
    );
    if (subMenu.name === type) {
      return { open: false };
    } else {
      return { open: true, ...route };
    }
  };

  const handleMenuItemAction = (slug, name) => {
    if (name === "Blogs") {
      navigate(`/${currentSection}/blogs/blogs`);
    } else {
      setSubmenu(getObject(slug));
    }
  };

  return (
    <>
      <div
        className={`drawer ${open ? "block" : "hidden"}`}
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal={open}
      >
        <div className="overlay"></div>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1 overflow-y-hidden px-4 py-6 sm:px-6 main-drawer">
                    <div className="flex items-center justify-between">
                      <img
                        src="/images/logo-gris.png"
                        alt="Logo sospechar no falla"
                        className="logo"
                      />
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          onClick={onClose}
                          type="button"
                          className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <ul>
                        {currentSection === "para-medicos" && (
                          <li
                            className={`profile-drawer nav-link ${
                              menuProfile
                                ? "bg-[#d7d8da] text-black"
                                : "bg-[#2a4252] text-white"
                            }`}
                            onClick={() => setMenuProfile(!menuProfile)}
                          >
                            <div
                              className={`profile-btn ${
                                menuProfile ? "mb-3" : ""
                              }`}
                            >
                              <img
                                src="/images/logo-user.png"
                                alt="Logo perfil"
                              />
                              <span>Perfil</span>
                            </div>
                            {menuProfile && <ProfileMenu />}
                          </li>
                        )}
                        <li
                          className={`nav-item active border-t-[1px] ${
                            isRoute(
                              currentSection === "para-pacientes"
                                ? "/para-pacientes/inicio"
                                : "/para-medicos/sobre-sospechar-no-falla"
                            )
                              ? "web"
                              : ""
                          }`}
                        >
                          <Link
                            to={
                              currentSection === "para-pacientes"
                                ? "/para-pacientes/inicio"
                                : "/para-medicos/sobre-sospechar-no-falla"
                            }
                            className="nav-link"
                          >
                            {currentSection === "para-pacientes"
                              ? "Inicio"
                              : "Sobre Sospechar No Falla"}
                          </Link>
                        </li>
                        {sections?.map((section) => (
                          <li
                            key={section.id}
                            className={`nav-item active ${
                              isRoute(`/${currentSection}/${section.slug}`)
                                ? "web"
                                : ""
                            }`}
                            onClick={() =>
                              handleMenuItemAction(section.slug, section.name)
                            }
                          >
                            <span className="nav-link">{section.name}</span>
                            <div
                              className="menu"
                              style={{
                                display:
                                  subMenu.open &&
                                  subMenu.name === section.slug
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div className="header">
                                <h1 className="title">{subMenu.title}</h1>
                                <h2 className="description">
                                  {subMenu.description}
                                </h2>
                              </div>
                              <div className="links">
                                {subMenu.items &&
                                  subMenu.items.map((it, index) => (
                                    <Link
                                      key={index}
                                      to={it.path}
                                      onClick={() =>
                                        setSubmenu({ open: false })
                                      }
                                    >
                                      {it.name} {">"}
                                    </Link>
                                  ))}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="items-center content-center font-helvetica-normal text-sm mt-1.5">
                        <a
                          className={`profile-drawer nav-link ${
                            menuProfile
                              ? "bg-[#d7d8da] text-black"
                              : "bg-[#2a4252] text-white"
                          }`}
                          href="https://portalweb-api.sospecharnofalla.com/Medias/downloadFile?path=medicos/infografias/EBOOK-SOSPECHAR-NO-FALLA&type=pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          eBook
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Drawer;