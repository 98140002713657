import { types } from "../types/types";

const initialState = {
  professions: [],
  specialties: [],
  sections: [],
  loginMessage: null,
  registerMessage: null,
  updateMessage: null,
  changePasswordMessage: null,
  loginLoading: false,
  updateUserLoading: false,
  changePasswordLoading: false,
  updateModalOpen: false,
  picModalOpen: false,
  registerLoading: false,
  professionsLoading: false,
  specialtiesLoading: false,
  sectionsLoading: false,
  pageLoading: false,
  lastSocialMediasLoading: false,
  lastMediasLoading: false,
  mediasLoading: false,
  mediaDataLoading: false,
  historyLoading: false,
  relatedMediasLoading: false,
  savedMediasLoading: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiClearMessages:
      return {
        ...state,
        loginMessage: null,
        registerMessage: null,
        updateMessage: null,
        changePasswordMessage: null,
      };

    case types.uiAuthStartLoginLoading:
      return {
        ...state,
        loginLoading: true,
      };

    case types.uiAuthFinishLoginLoading:
      return {
        ...state,
        loginLoading: false,
        loginMessage: action.payload,
      };

    case types.uiAuthStartRegisterLoading:
      return {
        ...state,
        registerLoading: true,
      };

    case types.uiAuthFinishRegisterLoading:
      return {
        ...state,
        registerLoading: false,
        registerMessage: action.payload,
      };

    case types.uiUserDataUpdateStartLoading:
      return {
        ...state,
        updateUserLoading: true,
      };

    case types.uiUserDataUpdateFinishLoading:
      return {
        ...state,
        updateUserLoading: false,
        updateMessage: action.payload,
      };

    case types.uiOpenUpdateModal:
      return {
        ...state,
        updateModalOpen: true,
      };

    case types.uiCloseUpdateModal:
      return {
        ...state,
        updateModalOpen: false,
      };

    case types.uiOpenPicModal:
      return {
        ...state,
        picModalOpen: true,
      };

    case types.uiClosePicModal:
      return {
        ...state,
        picModalOpen: false,
      };

    case types.uiChangePasswordStartLoading:
      return {
        ...state,
        changePasswordLoading: true,
      };

    case types.uiChangePasswordFinishtLoading:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordMessage: action.payload,
      };

    case types.uiProfessionsStartLoading:
      return {
        ...state,
        professionsLoading: true,
      };

    case types.uiProfessionsFinishLoading:
      return {
        ...state,
        professionsLoading: false,
      };

    case types.uiProfessionsLoaded:
      return {
        ...state,
        professions: action.payload,
      };

    case types.uiSectionsLoaded:
      return {
        ...state,
        sections: action.payload,
      };

    case types.uiSpecialtiesStartLoading:
      return {
        ...state,
        specialtiesLoading: true,
      };

    case types.uiSpecialtiesFinishLoading:
      return {
        ...state,
        specialtiesLoading: false,
      };

    case types.uiSpecialtiesLoaded:
      return {
        ...state,
        specialties: action.payload,
      };

    case types.uiSectionsStartLoading:
      return {
        ...state,
        sectionsLoading: true,
      };

    case types.uiSectionsFinishLoading:
      return {
        ...state,
        sectionsLoading: false,
      };

    case types.uiPageStartLoading:
      return {
        ...state,
        pageLoading: true,
      };

    case types.uiPageFinishLoading:
      return {
        ...state,
        pageLoading: false,
      };

    case types.uiLastSocialMediasStartLoading:
      return {
        ...state,
        lastSocialMediasLoading: true,
      };

    case types.uiLastSocialMediasFinishLoading:
      return {
        ...state,
        lastSocialMediasLoading: false,
      };

    case types.uiLastMediasStartLoading:
      return {
        ...state,
        lastMediasLoading: true,
      };

    case types.uiLastMediasFinishLoading:
      return {
        ...state,
        lastMediasLoading: false,
      };

    case types.uiMediasStartLoading:
      return {
        ...state,
        mediasLoading: true,
      };

    case types.uiMediasFinishLoading:
      return {
        ...state,
        mediasLoading: false,
      };

    case types.uiMediaDataStartLoading:
      return {
        ...state,
        mediaDataLoading: true,
      };

    case types.uiMediaDataFinishLoading:
      return {
        ...state,
        mediaDataLoading: false,
      };

    case types.uiMediasHistoryStartLoading:
      return {
        ...state,
        historyLoading: true,
      };

    case types.uiMediasHistoryFinishLoading:
      return {
        ...state,
        historyLoading: false,
      };

    case types.uiRelatedMediasStartLoading:
      return {
        ...state,
        relatedMediasLoading: true,
      };

    case types.uiRelatedMediasFinishLoading:
      return {
        ...state,
        relatedMediasLoading: false,
      };

    case types.uiMediasSavedStartLoading:
      return {
        ...state,
        savedMediasLoading: true,
      };

    case types.uiMediasSavedFinishLoading:
      return {
        ...state,
        savedMediasLoading: false,
      };

    default:
      return state;
  }
};
